import React, { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import AppShell from '../../components/layout/AppShell';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { createPortalSession } from '../../services/subscriptionService';
import { updateUserProfile } from '../../services/userService';
import { Helmet } from 'react-helmet';

function capitalizeFirstLetter(string) {
    if (typeof string !== 'string') {
        return typeof string === 'number'
            ? string.toString().charAt(0).toUpperCase() + string.toString().slice(1)
            : '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const Profile = () => {
    const { user, refreshUserData } = useUser();
    const [formData, setFormData] = useState({
        name: '',
        organisation: '',
        email: '',
        subscription_plan: '',
        subscription_status: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');

    useEffect(() => {
        if (user) {
            setFormData({
                name: user.name,
                organisation: user.organisation,
                email: user.email,
                subscription_plan: user.subscription_name,
                subscription_status: user.subscription_status,
            });
            setLoading(false);
        } else {
            setError('Failed to load user profile. Please try again.');
            setLoading(false);
        }
    }, [user]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const profileData = {
            name: formData.name.trim(),
            organisation: formData.organisation.trim(),
        };

        // Validation: Ensure that name is not empty
        if (!profileData.name) {
            setAlertMessage('Name cannot be empty.');
            setAlertType('error');
            setShowAlert(true);
            setLoading(false);
            return;
        }

        // Check if data has changed
        const isProfileDataChanged =
            profileData.name !== user.name || profileData.organisation !== user.organisation;

        try {
            if (isProfileDataChanged) {
                await updateUserProfile(profileData); // Correctly calling the API endpoint
                setAlertMessage('Profile updated successfully.');
                setAlertType('success');
                setShowAlert(true);
                refreshUserData();
            } else {
                setAlertMessage('No changes made to the profile.');
                setAlertType('info');
                setShowAlert(true);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
            setAlertMessage('Error updating profile. Please try again.');
            setAlertType('error');
            setShowAlert(true);
        }

        setLoading(false);
    };

    const handleManageSubscription = async () => {
        try {
            const currentUrl = window.location.href;
            const portalUrl = await createPortalSession(currentUrl);
            window.location.href = portalUrl;
        } catch (error) {
            console.error('Error managing subscription:', error);
            setAlertMessage('Error managing subscription. Please try again.');
            setAlertType('error');
            setShowAlert(true);
        }
    };

    const handleCancel = () => {
        setFormData({
            name: user.name || '',
            organisation: user.organisation || '',
            email: user.email || '',
            subscription_plan: user.subscription_name || '',
            subscription_status: user.subscription_status || '',
        });

        setError(null);
        setAlertMessage('No changes have been saved.');
        setAlertType('info');
        setShowAlert(true);
    };

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return (
            <AlertPopup message={error} type="error" onClose={() => setError(null)} />
        );
    }

    return (
        <AppShell heading="Profile" currentPage="Profile" loginRequired={true}>
            <Helmet>
                <title>Your Profile</title>
            </Helmet>
            <div className="min-h-[calc(100vh-25vh)]">
                <h2 className="text-2xl font-semibold mb-4">User Details</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mt-6 flex flex-row gap-4">
                        {user.subscription_status !== 'Inactive' ? (
                            <>
                                <div className="bg-white shadow-md rounded-md p-6 mb-6 flex-1">
                                    <h3 className="text-lg text-gray-800 font-semibold mb-2">
                                        Subscription Status
                                    </h3>
                                    <p className="text-gray-600">
                                        {capitalizeFirstLetter(user.subscription_status)}
                                    </p>
                                    {user.subscription_plan !== 'free' && (
                                        <button
                                            type="button"
                                            className="mt-3 bg-indigo-600 text-white px-4 py-2 text-sm rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            onClick={handleManageSubscription}
                                        >
                                            Manage Subscription
                                        </button>
                                    )}
                                </div>
                                <div className="bg-white shadow-md rounded-md p-6 mb-6 flex-1">
                                    <h3 className="text-lg text-gray-800 font-semibold mb-2">
                                        Subscription Plan
                                    </h3>
                                    <p className="text-gray-600">
                                        {capitalizeFirstLetter(user.subscription_name)}
                                    </p>
                                </div>
                                <div className="bg-white shadow-md rounded-md p-6 mb-6 flex-1">
                                    <h4 className="text-lg text-gray-800 font-semibold mb-2">
                                        Credits Remaining
                                    </h4>
                                    <p className="text-gray-600">{user.credits_remaining}</p>
                                </div>
                            </>
                        ) : (
                            <div className="w-full flex justify-center">
                                <button
                                    type="button"
                                    className="mt-3 bg-red-500 text-white px-6 py-2 text-md rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                                    onClick={() => (window.location.href = '/pricing')}
                                >
                                    View Pricing
                                </button>
                            </div>
                        )}
                    </div>

                    <div className="space-y-12">
                        <div className="border-b border-gray-200 pb-12">
                            <h2 className="text-base font-semibold leading-7 text-gray-800">
                                Personal Information
                            </h2>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium leading-6 text-gray-800"
                                    >
                                        Name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-gray-300 py-2 px-3 text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Enter your name"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="organisation"
                                        className="block text-sm font-medium leading-6 text-gray-800"
                                    >
                                        Organisation
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="organisation"
                                            id="organisation"
                                            value={formData.organisation}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-gray-300 py-2 px-3 text-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                            placeholder="Enter your organisation"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-800"
                                    >
                                        Email Address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={formData.email}
                                            readOnly
                                            className="block w-full rounded-md border-gray-300 py-2 px-3 text-gray-500 bg-gray-100 shadow-sm sm:text-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="button"
                            className="text-sm font-semibold leading-6 text-gray-700 hover:text-gray-900"
                            onClick={handleCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="inline-flex justify-center rounded-md bg-indigo-600 py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
            {showAlert && (
                <AlertPopup
                    message={alertMessage}
                    type={alertType}
                    onClose={() => setShowAlert(false)}
                    duration={3000}
                />
            )}
        </AppShell>
    );
};

export default Profile;
