// ModalComponent.js
import React, { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import RadioButtonGroup from './RadioButtonGroup';
import IconUpload from './IconUploadComponent';
import NumberInput from './NumberInput';
import { XMarkIcon } from '@heroicons/react/24/outline';

const OutputMode = [
  { id: 1, title: 'Strict', description: '' },
  { id: 2, title: 'Flexible', description: '' },
];

const OutputLength = [
  { id: 1, title: 'Concise', description: '' },
  { id: 2, title: 'Regular', description: '' },
  { id: 3, title: 'Detailed', description: '' },
];

const OutputPerspective = [
  { id: 1, title: 'FirstPerson', description: '' },
  { id: 2, title: 'ThirdPerson', description: '' },
];

export default function ModalComponent({ open, onClose, onOptionsChange, options, cvIcon }) {
  const [localOptions, setLocalOptions] = useState(options);

  useEffect(() => {
    setLocalOptions(options);
  }, [options]);

  const handleModeSelect = (option) => {
    const newOptions = { ...localOptions, mode: option.title };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handleLengthSelect = (option) => {
    const newOptions = { ...localOptions, length: option.title };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handlePerspectiveSelect = (option) => {
    const newOptions = { ...localOptions, perspective: option.title };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handleSectionLimitChange = (section, value) => {
    const newOptions = {
      ...localOptions,
      sectionLimits: { ...localOptions.sectionLimits, [section]: value },
    };
    setLocalOptions(newOptions);
    onOptionsChange(newOptions);
  };

  const handleSave = () => {
    // Optionally, you can add any save logic here.
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="flex items-start justify-between">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      CV Options
                    </Dialog.Title>
                    <div className="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={onClose}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>

                  <div className="mt-4 space-y-6">
                    <IconUpload savedIcon={cvIcon} />
                    <RadioButtonGroup
                      title="Output Mode"
                      options={OutputMode}
                      selectedOption={localOptions.mode}
                      onSelect={handleModeSelect}
                      name="output-mode"
                      TooltipMessage="Strict will ensure that the CV is generated with the most accurate information. Flexible will allow for some creative liberties."

                    />
                    <RadioButtonGroup
                      title="Output Length"
                      options={OutputLength}
                      selectedOption={localOptions.length}
                      onSelect={handleLengthSelect}
                      name="output-length"
                      TooltipMessage="Controls the overall text length of the CV."
                    />
                    <RadioButtonGroup
                      title="Output Perspective"
                      options={OutputPerspective}
                      selectedOption={localOptions.perspective}
                      onSelect={handlePerspectiveSelect}
                      name="output-perspective"
                      TooltipMessage="First Person will generate the CV as if the user is speaking. Third Person will generate the CV as if someone else is speaking."
                    />
                    <div className="pt-4">
                      <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                        Section Limits
                      </h3>
                      <div className="space-y-4">
                        <NumberInput
                          title="Experience"
                          description="Maximum number of experiences to include."
                          value={localOptions.sectionLimits.experience}
                          onChange={(value) => handleSectionLimitChange('experience', value)}
                          min={1}
                          max={10}
                        />
                        <NumberInput
                          title="Projects"
                          description="Maximum number of projects to include."
                          value={localOptions.sectionLimits.project}
                          onChange={(value) => handleSectionLimitChange('project', value)}
                          min={1}
                          max={10}
                        />
                        <NumberInput
                          title="Skills"
                          description="Maximum number of skills to include."
                          value={localOptions.sectionLimits.skill}
                          onChange={(value) => handleSectionLimitChange('skill', value)}
                          min={1}
                          max={20}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
