import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../services/userService';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { Helmet } from 'react-helmet';
import Tooltip from '../../components/layout/Tooltip';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { getAccessToken } from '../../services/userService';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z.,!~?]{8,}$/;


const Register = () => {
    const passwordRequirements = `
    Password must meet the following criteria:
    - At least 8 characters long
    - At least one digit
    - At least one alphabet character
    `;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        organization: '',
        password: '',
        confirmPassword: '',
    });
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [popupType, setPopupType] = useState('success');
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [isAlreadyLoggedIn, setIsAlreadyLoggedIn] = useState(false);

    useEffect(() => {
        const checkLoggedIn = async () => {
            try {
                await getAccessToken();
                setIsAlreadyLoggedIn(true);
                setTimeout(() => {
                    navigate('/');
                }, 3000); // Redirect after 3 seconds
            } catch (error) {
                // No valid access token, so do nothing
            }
        };

        checkLoggedIn();
    }, [navigate]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleTermsChange = (e) => {
        setAcceptedTerms(e.target.checked);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        if (!passwordRegex.test(formData.password)) {
            newErrors.password =
                'Password must be at least 8 characters long and contain at least one letter and one number';
        }

        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match';
        }

        if (!acceptedTerms) {
            newErrors.terms = 'You must accept the terms and conditions';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (!validateForm()) {
            setIsLoading(false);
            return;
        }

        try {
            const registrationData = {
                name: formData.name,
                email: formData.email,
                password: formData.password,
                organisation: formData.organization,
            };

            await registerUser(registrationData);

            setPopupMessage('Registration successful! Redirecting to login page...');
            setPopupType('success');
            setShowPopup(true);
            setTimeout(() => {
                navigate('/login');
            }, 1000);
        } catch (error) {
            const errorMessage = error.response?.data?.error || 'Registration failed. Please check your information and try again.';
            setPopupMessage(errorMessage);
            setPopupType('error');
            setShowPopup(true);
        }

        setIsLoading(false);
    };

    if (isAlreadyLoggedIn) {
        return (
            <div className="flex min-h-screen items-center justify-center">
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-4">Already Logged In</h2>
                    <p>You are already logged in. Redirecting to the home page...</p>
                </div>
                {showPopup && (
                    <AlertPopup
                        message={popupMessage}
                        type={popupType}
                        onClose={() => setShowPopup(false)}
                    />
                )}
            </div>
        );
    }

    return (
        <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeInFast">
            <Helmet>
                <title>Register | AI-Powered CV Converter</title>
                <meta
                    name="description"
                    content="Sign up for free and unlock the power of our AI-driven CV Converter! Create an account in just a few clicks and convert your resume into a professionally tailored document."
                />
                <meta
                    name="keywords"
                    content="register, sign up, create account, free account, no credit card required, instant access, easy registration, quick sign up, CV converter, resume converter, free CV converter, free resume converter, AI-powered CV converter, convert CV online, convert resume online, tailor CV, tailor resume, customize CV, customize resume, optimize CV, optimize resume, professional CV, professional resume, job application, career boost, increase job prospects, land dream job"
                />
            </Helmet>
            <div className="flex min-h-[calc(100vh-25vh)] flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <a href="/">
                        <img
                            className="mx-auto h-12 w-auto"
                            src="/PivotCV-Icon-Dark.svg"
                            alt="Your Company"
                        />
                    </a>
                    <h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-darkGrey">
                        Create your account
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-darkGrey">
                                Name
                            </label>
                            <div className="mt-2">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    autoComplete="name"
                                    required
                                    value={formData.name}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${errors.name ? 'ring-red-500' : 'ring-gray-300'
                                        }`}
                                />
                                {errors.name && (
                                    <p className="mt-2 text-sm text-red-500">{errors.name}</p>
                                )}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-darkGrey">
                                Email address
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${errors.email ? 'ring-red-500' : 'ring-gray-300'
                                        }`}
                                />
                                {errors.email && (
                                    <p className="mt-2 text-sm text-red-500">{errors.email}</p>
                                )}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="organization" className="block text-sm font-medium leading-6 text-darkGrey">
                                Organisation (Optional)
                            </label>
                            <div className="mt-2">
                                <input
                                    id="organization"
                                    name="organization"
                                    type="text"
                                    autoComplete="organization"
                                    value={formData.organization}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-darkGrey shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div>
                            <Tooltip message={passwordRequirements} position="left" skew='left'>
                                <label htmlFor="password" className="block text-sm font-medium leading-6 text-darkGrey">
                                    Password
                                </label>
                            </Tooltip>
                            <div className="mt-2 relative">
                                <input
                                    id="password"
                                    name="password"
                                    type={showPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    required
                                    value={formData.password}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 pr-10 text-darkGrey shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${errors.password ? 'ring-red-500' : 'ring-gray-300'}`}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                                >
                                    {showPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    )}
                                </button>
                                {errors.password && (
                                    <p className="mt-2 text-sm text-red-500">{errors.password}</p>
                                )}
                            </div>
                        </div>

                        <div>
                            <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-darkGrey">
                                Confirm Password
                            </label>
                            <div className="mt-2 relative">
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    autoComplete="new-password"
                                    required
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    className={`block w-full rounded-md border-0 py-1.5 pr-10 text-darkGrey shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${errors.confirmPassword ? 'ring-red-500' : 'ring-gray-300'}`}
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                                >
                                    {showConfirmPassword ? (
                                        <EyeSlashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    ) : (
                                        <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    )}
                                </button>
                                {errors.confirmPassword && (
                                    <p className="mt-2 text-sm text-red-500">{errors.confirmPassword}</p>
                                )}
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="terms" className="flex items-start">
                                <input
                                    id="terms"
                                    type="checkbox"
                                    required
                                    checked={acceptedTerms}
                                    onChange={handleTermsChange}
                                    className="h-4 w-4 accent-indigo-600"
                                />
                                <span className="ml-2 text-sm text-slateGrey">
                                    I accept the <a href="/PivotCV-Website-Terms-and-Conditions-of-Use-16-05-2024.pdf" className="text-primaryBlue hover:text-lightBlue">Terms and Conditions</a>
                                </span>
                            </label>
                            {errors.terms && (
                                <p className="mt-2 text-sm text-red-500">{errors.terms}</p>
                            )}
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primaryBlue px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-lightBlue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                {isLoading ? <Spinner /> : 'Register'}
                            </button>
                        </div>
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        Already have an account?{' '}
                        <a href="/login" className="font-semibold leading-6 text-primaryBlue hover:text-lightBlue">
                            Sign in
                        </a>
                    </p>
                </div>
                <div className="mt-6 text-center">
                    <a
                        href="/"
                        className="inline-flex items-center text-sm font-medium text-slateGrey hover:text-gray-800"
                    >
                        <svg className="-ml-1 mr-1.5 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                        </svg>
                        Back to Home
                    </a>
                </div>
            </div>

            {showPopup && (
                <AlertPopup
                    message={popupMessage}
                    type={popupType}
                    onClose={() => setShowPopup(false)}
                />
            )}
        </div>
    );
};

export default Register;
