import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { getUserProfile, getAccessToken } from '../services/userService';
import { useNavigate } from 'react-router-dom';
import { lineWobble } from 'ldrs';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  lineWobble.register();

  const navigate = useNavigate();
  const [user, setUser] = useState(null); // Initialize user as null
  const [loading, setLoading] = useState(true); // Start with loading=true
  const [error, setError] = useState(null);

  const logoutUser = useCallback(() => {
    // Remove tokens and clear user state
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    setUser(null);
    navigate('/login'); // Redirect to login
  }, [navigate]);

  const handleErrors = useCallback(
    (error) => {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 400:
            setError('Bad request. Please check your input and try again.');
            break;
          case 401:
            setError('Unauthorized. Please log in again.');
            logoutUser(); // Use context logout
            break;
          case 403:
            setError('Forbidden. You do not have permission to access this resource.');
            break;
          case 404:
            setError('Resource not found. Please check the URL and try again.');
            break;
          case 500:
            setError('Internal server error. Please try again later.');
            break;
          case 503:
            setError('Service unavailable. Please try again later.');
            break;
          default:
            setError('An error occurred. Please try again.');
            break;
        }
      } else if (error.request) {
        setError('No response received from the server. Please check your internet connection and try again.');
      } else if (error.message) {
        setError(error.message);
      } else {
        setError('An error occurred. Please try again.');
      }
    },
    [logoutUser]
  );

  const fetchUserData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      await getAccessToken(); // This will refresh the token if needed
      const userData = await getUserProfile();
      setUser(userData); // Store all user data
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [handleErrors]);

  useEffect(() => {
    fetchUserData(); // Call on component mount
  }, [fetchUserData]);

  const refreshUserData = useCallback(() => fetchUserData(), [fetchUserData]);

  const contextValue = useMemo(
    () => ({
      user,
      loading,
      error,
      refreshUserData,
      logoutUser,
    }),
    [user, loading, error, refreshUserData, logoutUser]
  );

  if (loading && !user) {
    return (
      <div className="inset-0 fixed flex w-full h-full items-center justify-center duration-300 transition-opacity" style={{ zIndex: 6000 }}>
        <div className="flex-col">
          <l-line-wobble size="80" stroke="5" bg-opacity="0.1" speed="1.75" color="black"></l-line-wobble>
        </div>
      </div>
    );
  }

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

export default UserProvider;
