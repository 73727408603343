import React, { useState, useEffect, useCallback } from 'react';
import {
  getUsersTable,
  updateUser,
  createUser,
  generateDemoAccount,
  deleteUser,
} from '../../services/adminService';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import AppShell from '../../components/layout/AppShell';
import AddUserPopup from '../../components/Admin/AddUserPopUp';
import EditUserPopup from '../../components/Admin/EditUserPopUp';
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { Helmet } from 'react-helmet';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  UserPlusIcon,
  UserIcon,
  TrashIcon,
  PencilIcon,
} from '@heroicons/react/24/solid';

const ITEMS_PER_PAGE_OPTIONS = [10, 20, 50];

export default function Admin() {
  const {
    user,
    loading: userLoading,
    error: userError,
    refreshUserData,
  } = useUser();
  const navigate = useNavigate();

  // State for User Table Data
  const [usersTable, setUsersTable] = useState([]);
  const [loading, setLoading] = useState(false); // Separate loading state
  const [error, setError] = useState(null);
  const [totalPages, setTotalPages] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  // State for Pagination, Sorting, and Search
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(
    ITEMS_PER_PAGE_OPTIONS[0]
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  // State for Modals and Alerts
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAddUserPopupOpen, setIsAddUserPopupOpen] = useState(false);
  const [isDemoPopupOpen, setIsDemoPopupOpen] = useState(false);
  const [demoAccount, setDemoAccount] = useState(null);
  const [demoName, setDemoName] = useState('');
  const [demoOrganisation, setDemoOrganisation] = useState('');
  const [isGeneratingDemo, setIsGeneratingDemo] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [showAlert, setShowAlert] = useState(false);

  // Alert Handler
  const showAlertMessage = useCallback((message, type = 'success') => {
    setAlertMessage(message);
    setAlertType(type);
    setError(type === 'error' ? message : null);
    setShowAlert(true);
  }, []);

  // Fetch Users Table Data
  const fetchUsersTable = useCallback(async () => {
    try {
      setLoading(true);
      const usersTableData = await getUsersTable(
        currentPage,
        itemsPerPage,
        searchTerm,
        sortColumn,
        sortDirection
      );

      if (usersTableData && Array.isArray(usersTableData.users)) {
        setUsersTable(usersTableData.users);
        setTotalUsers(usersTableData.total_items);
        setTotalPages(usersTableData.total_pages);
      } else {
        console.error(
          'Unexpected usersTableData structure:',
          usersTableData
        );
        setUsersTable([]);
        setTotalUsers(0);
        setTotalPages(1);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      showAlertMessage('Error fetching data. Please try again.', 'error');
      setUsersTable([]);
      setTotalUsers(0);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    itemsPerPage,
    searchTerm,
    sortColumn,
    sortDirection,
    showAlertMessage,
  ]);

  // Handle User Authentication and Authorization
  useEffect(() => {
    if (userLoading) return; // Wait until user data is loaded

    if (user && user.user_role === 'admin') {
      fetchUsersTable();
    } else if (user && user.user_role !== 'admin') {
      showAlertMessage('Unauthorized access.', 'error');
      navigate('/');
    }
  }, [
    user,
    userLoading,
    fetchUsersTable,
    navigate,
    showAlertMessage,
  ]);

  // Fetch Users Table Data when Pagination, Sorting, or Search Changes
  useEffect(() => {
    if (user && user.user_role === 'admin') {
      fetchUsersTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    itemsPerPage,
    searchTerm,
    sortColumn,
    sortDirection,
  ]);

  // Handlers for Pagination, Sorting, and Search
  const handlePageChange = useCallback((newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  }, [totalPages]);

  const handleItemsPerPageChange = useCallback((event) => {
    setItemsPerPage(Number(event.target.value));
    setCurrentPage(1);
  }, []);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  }, []);

  const handleSort = useCallback((column) => {
    if (sortColumn === column) {
      // Toggle sort direction
      setSortDirection((prevDirection) =>
        prevDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(column);
      setSortDirection('asc'); // Default to ascending when changing column
    }
    setCurrentPage(1);
  }, [sortColumn]);

  // Handlers for User Actions
  const handleGenerateDemoAccount = useCallback(async () => {
    try {
      setIsGeneratingDemo(true);
      const account = await generateDemoAccount(demoName, demoOrganisation);
      setDemoAccount(account);
      showAlertMessage('Demo account created successfully!', 'success');
    } catch (err) {
      console.error('Error generating demo account:', err);
      showAlertMessage('Failed to generate demo account', 'error');
    } finally {
      setIsGeneratingDemo(false);
    }
  }, [demoName, demoOrganisation, showAlertMessage]);

  const handleDeleteUser = useCallback(
    async (userId) => {
      if (
        window.confirm(
          'Are you sure you want to delete this user? This action cannot be undone.'
        )
      ) {
        try {
          setLoading(true);
          await deleteUser(userId);
          showAlertMessage('User deleted successfully!', 'success');
          // Optimistically update the UI without refetching
          setUsersTable((prevUsers) =>
            prevUsers.filter((user) => user.id !== userId)
          );
          setTotalUsers((prevTotal) => prevTotal - 1);
        } catch (err) {
          console.error('Error deleting user:', err);
          showAlertMessage('Error deleting user. Please try again.', 'error');
        } finally {
          setLoading(false);
        }
      }
    },
    [showAlertMessage]
  );

  const handleUpdateUser = useCallback(
    async (updatedUser) => {
      try {
        setLoading(true);
        await updateUser(updatedUser);
        showAlertMessage('User updated successfully!', 'success');
        // Optimistically update the user in the UI
        setUsersTable((prevUsers) =>
          prevUsers.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
          )
        );
      } catch (err) {
        console.error('Error updating user:', err);
        showAlertMessage('Error updating user. Please try again.', 'error');
      } finally {
        setLoading(false);
      }
    },
    [showAlertMessage]
  );

  const handleCreateUser = useCallback(
    async (newUser) => {
      try {
        setLoading(true);
        const createdUser = await createUser(newUser);
        showAlertMessage('User created successfully!', 'success');
        // Optimistically add the new user to the UI
        setUsersTable((prevUsers) => [createdUser, ...prevUsers]);
        setTotalUsers((prevTotal) => prevTotal + 1);
      } catch (err) {
        console.error('Error creating user:', err);
        showAlertMessage('Error creating user. Please try again.', 'error');
      } finally {
        setLoading(false);
      }
    },
    [showAlertMessage]
  );

  // Conditional Rendering for Loading and Errors
  if (userLoading || loading) {
    return <Spinner />;
  }

  if (userError) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="mt-48 text-center">
          <h2 className="text-2xl font-semibold mb-4">
            An error occurred while loading user data.
          </h2>
          <div className="flex justify-center space-x-4">
            <button
              onClick={refreshUserData}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
            >
              Retry
            </button>
            <a
              href="/"
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            >
              Go Home
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (user && user.user_role !== 'admin') {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="mt-48 text-center">
          <h2 className="text-2xl font-semibold mb-4">
            Unauthorized access.
          </h2>
          <div className="flex justify-center space-x-4">
            <a
              href="/"
              className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
            >
              Go Home
            </a>
          </div>
        </div>
      </div>
    );
  }

  // Main Admin Dashboard Render
  return (
    <AppShell currentPage="Admin" loginRequired>
      <Helmet>
        <title>Admin Dashboard</title>
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Admin Dashboard
        </h1>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center flex-wrap gap-4">
            <div>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                User Management
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Manage users and their accounts
              </p>
            </div>
            <div className="flex space-x-4">
              <button
                onClick={() => setIsDemoPopupOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <UserIcon className="mr-2 h-5 w-5" />
                Generate Demo Account
              </button>
              <button
                onClick={() => setIsAddUserPopupOpen(true)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <UserPlusIcon className="mr-2 h-5 w-5" />
                Add User
              </button>
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 border-t border-gray-200">
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                  {[
                    'Name',
                    'Email',
                    'Organisation',
                    'User Role',
                    'Subscription Plan',
                    'Subscription Status',
                    'Converted CVs Count',
                    'Credits Remaining',
                    'Created At',
                    'Last Login Time',
                  ].map((header) => {
                    const columnKey = header
                      .toLowerCase()
                      .replace(/ /g, '_');
                    return (
                      <th
                        key={header}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                        onClick={() => handleSort(columnKey)}
                      >
                        {header}
                        {sortColumn === columnKey && (
                          <span>
                            {sortDirection === 'asc' ? ' ▲' : ' ▼'}
                          </span>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {loading ? (
                  <tr>
                    <td
                      colSpan="11"
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      <Spinner />
                    </td>
                  </tr>
                ) : usersTable && usersTable.length > 0 ? (
                  usersTable.map((user) => (
                    <tr key={user.email}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button
                          onClick={() => {
                            setSelectedUser(user);
                            setIsPopupOpen(true);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 mr-2"
                        >
                          <PencilIcon className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteUser(user.id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <TrashIcon className="h-5 w-5" />
                        </button>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.name}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.email}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.organisation || '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.user_role}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.subscription_plan}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.subscription_status}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.converted_cvs_count}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.credits_remaining}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(user.created_at).toLocaleString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {user.last_login_time
                          ? new Date(user.last_login_time).toLocaleString()
                          : 'Never'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="11"
                      className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
                    >
                      No users found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination and Items Per Page */}
        <div className="mt-6 flex items-center justify-between flex-wrap gap-4">
          <div className="flex-1 flex justify-between sm:hidden">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
          </div>
          <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                {totalUsers > 0 ? (
                  <>
                    Showing{' '}
                    <span className="font-medium">
                      {(currentPage - 1) * itemsPerPage + 1}
                    </span>{' '}
                    to{' '}
                    <span className="font-medium">
                      {Math.min(currentPage * itemsPerPage, totalUsers)}
                    </span>{' '}
                    of{' '}
                    <span className="font-medium">{totalUsers}</span> results
                  </>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div>
              <nav
                className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                aria-label="Pagination"
              >
                <button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
                <button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </nav>
            </div>
          </div>
          <div className="flex items-center">
            <label
              htmlFor="itemsPerPage"
              className="mr-2 text-sm text-gray-600"
            >
              Show:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            >
              {ITEMS_PER_PAGE_OPTIONS.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {/* Modals and Popups */}
      {isAddUserPopupOpen && (
        <AddUserPopup
          open={isAddUserPopupOpen}
          setOpen={setIsAddUserPopupOpen}
          onSave={handleCreateUser}
        />
      )}
      {selectedUser && (
        <EditUserPopup
          open={isPopupOpen}
          setOpen={setIsPopupOpen}
          user={selectedUser}
          onSave={handleUpdateUser}
        />
      )}

      {showAlert && (
        <AlertPopup
          message={error || alertMessage}
          type={alertType}
          onClose={() => setShowAlert(false)}
          duration={2000}
        />
      )}

      {isDemoPopupOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={() => setIsDemoPopupOpen(false)}
        >
          <div
            className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Generate Demo Account
              </h3>
              <div className="mt-2 px-7 py-3">
                <input
                  type="text"
                  placeholder="Name (optional)"
                  value={demoName}
                  onChange={(e) => setDemoName(e.target.value)}
                  className="mt-2 p-2 w-full border rounded-md"
                />
                <input
                  type="text"
                  placeholder="Organisation (optional)"
                  value={demoOrganisation}
                  onChange={(e) => setDemoOrganisation(e.target.value)}
                  className="mt-2 p-2 w-full border rounded-md"
                />
              </div>
              <div className="items-center px-4 py-3">
                {!demoAccount ? (
                  <button
                    onClick={handleGenerateDemoAccount}
                    disabled={isGeneratingDemo}
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:bg-gray-400"
                  >
                    {isGeneratingDemo
                      ? 'Generating...'
                      : 'Create Demo Account'}
                  </button>
                ) : (
                  <>
                    <p className="text-sm text-gray-500 mb-2">
                      Email: {demoAccount.email}
                    </p>
                    <p className="text-sm text-gray-500 mb-4">
                      Password: {demoAccount.password}
                    </p>
                    <button
                      onClick={() => {
                        const accountDetails = `Email: ${demoAccount.email}\nPassword: ${demoAccount.password}`;
                        navigator.clipboard.writeText(accountDetails);
                        showAlertMessage(
                          'Demo account details copied to clipboard',
                          'success'
                        );
                      }}
                      className="px-4 py-2 bg-green-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                      Copy Account Details
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </AppShell>
  );
}
