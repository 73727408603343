// userService.js

import { axiosInstance } from './HelperService';
import { jwtDecode } from 'jwt-decode';

const setAuthToken = (token) => {
    if (token) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

const refreshAccessToken = async (refreshToken) => {
    try {
      const response = await axiosInstance.post('/auth/refresh', null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      });
      const { access_token, refresh_token } = response.data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      setAuthToken(access_token); // Set the new access token in the headers
      return access_token;
    } catch (error) {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      throw new Error('Failed to refresh token');
    }
  };

  const getAccessToken = async () => {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      throw new Error('Access token not found');
    }
  
    const decodedToken = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    const tokenExpirationTime = decodedToken.exp;
  
    if (tokenExpirationTime <= currentTime) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        throw new Error('Refresh token not found');
      }
      return await refreshAccessToken(refreshToken);
    }
  
    return accessToken;
  };

  const getUserProfile = async () => {
    try {
      const accessToken = await getAccessToken();
      setAuthToken(accessToken); // Ensure the Authorization header is set
      const response = await axiosInstance.get('/auth/profile');
      return response.data;
    } catch (error) {
      throw error;
    }
  };

const updateUserProfile = async (profileData) => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const response = await axiosInstance.post('/auth/update_profile', profileData);
        localStorage.setItem('user_profile_updated', Date.now().toString());
        return response.data;
    } catch (error) {
        throw error;
    }
};

const uploadUserIcon = async (iconFile) => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const formData = new FormData();
        formData.append('icon', iconFile);

        const response = await axiosInstance.post('/auth/upload-icon', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

const loginUser = async (email, password) => {
    try {
      const response = await axiosInstance.post('/auth/login', { email, password });
      const { access_token, refresh_token } = response.data;
      localStorage.setItem('access_token', access_token);
      localStorage.setItem('refresh_token', refresh_token);
      setAuthToken(access_token); // Set the access token in headers
      return response.data;
    } catch (error) {
      throw error;
    }
  };

const registerUser = async (userData) => {
    try {
        const response = await axiosInstance.post('/auth/register', userData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

const logoutUser = async () => {
    try {
      await axiosInstance.delete('/auth/logout');
      localStorage.clear();
      window.location.href = '/'; // Redirect to the home page
    } catch (error) {
      throw error;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.href = '/'; // Redirect to login page
  };

const requestPasswordReset = async (email) => {
    try {
        const response = await axiosInstance.post('/auth/request_password_reset', { email });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 429) {
            throw new Error('Too many requests. Please try again later.');
        }
        throw error;
    }
};

const resetPassword = async (email, code, newPassword) => {
    try {
        const response = await axiosInstance.post('/auth/reset_password_with_code', {
            email,
            code,
            new_password: newPassword,
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 429) {
            throw new Error('Too many requests. Please try again later.');
        }
        throw error;
    }
};

const clearUserIcon = async () => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const response = await axiosInstance.post('/auth/clear-icon');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const clearDefaultCVIcon = async () => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const response = await axiosInstance.delete('/auth/remove-cv-icon');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const getUserCreditsRemaining = async () => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const response = await axiosInstance.get('/auth/credits');
        return response.data;
    } catch (error) {
        throw error;
    }
};

const uploadDefaultCVIcon = async (iconFile) => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const formData = new FormData();
        formData.append('icon', iconFile);

        const response = await axiosInstance.post('/convert/upload-cv-icon', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            validateStatus: function (status) {
                return status >= 200 && status < 500; // Accept status codes less than 500
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};

const removeDefaultCVIcon = async () => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const response = await axiosInstance.delete('/convert/remove-cv-icon', {
            validateStatus: function (status) {
                return status >= 200 && status < 500; // Accept status codes less than 500
            },
        });
        return response;
    } catch (error) {
        throw error;
    }
};


const getDefaultCVIcon = async () => {
    try {
        const accessToken = await getAccessToken();
        setAuthToken(accessToken);
        const response = await axiosInstance.get('/convert/get-cv-icon');
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return null;
        }
        throw error;
    }
};

export {
    getUserProfile,
    updateUserProfile,
    getAccessToken,
    uploadUserIcon,
    loginUser,
    registerUser,
    logoutUser,
    setAuthToken,
    refreshAccessToken,
    requestPasswordReset,
    resetPassword,
    clearUserIcon,
    clearDefaultCVIcon,
    getUserCreditsRemaining,
    uploadDefaultCVIcon,
    removeDefaultCVIcon,
    getDefaultCVIcon,
    handleLogout,
};
