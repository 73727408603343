import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';

export const useUser = () => {
  const { user, loading, error, refreshUserData, logoutUser } = useContext(UserContext);
  if (user === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return { user, loading, error, refreshUserData, logoutUser };
};

export default useUser;
