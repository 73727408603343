import React, { useState, useEffect, useCallback } from 'react';
import { Loader2, FileText, Download, RefreshCw, ChevronRight, ChevronLeft } from 'lucide-react';
import {
  initiateBulkCVConversion,
  getCVConversionResults,
  downloadConvertedResumes,
} from '../../services/JSONService';
import FileUpload from '../../components/ui/FileUpload';
import Appshell from '../../components/layout/AppShell';

const templates = ['Generic', 'Modern'];

const adherences = ['Strict', 'Flexible'];

const perspectives = ['First Person', 'Third Person'];

const textlength = ['Concise', 'Balanced', 'Detailed'];

const facts = [
  'Did you know? The average recruiter spends only 6 seconds reviewing a resume.',
  'Tip: Tailor your resume to each job application for better results.',
  "Fun fact: The word 'resume' comes from the French word meaning 'to summarize'.",
  'Studies show that 76% of resumes are discarded for an unprofessional email address.',
  "Including a link to your LinkedIn profile can increase your resume's visibility.",
  'Quantifying your achievements with numbers can make your resume 40% more likely to be read.',
];

export default function ApplicationDemo() {
  const [step, setStep] = useState(1);
  const [resumeFile, setResumeFile] = useState(null);
  const [jobDescriptionFile, setJobDescriptionFile] = useState(null);
  const [template, setTemplate] = useState('Generic');
  const [adherence, setAdherence] = useState(false);
  const [textLength, setTextLength] = useState(1);
  const [perspective, setPerspective] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [currentFact, setCurrentFact] = useState(0);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [downloadFilename, setDownloadFilename] = useState(null);
  const [fadeIn, setFadeIn] = useState(true);

  const transitionToNextStep = useCallback((nextStep) => {
    setTransitioning(true);
    setTimeout(() => {
      setStep(nextStep);
      setTransitioning(false);
    }, 500);
  }, []);

  const handleResumeUpload = useCallback(
    (file) => {
      setResumeFile(file);
      if (file) {
        setTimeout(() => {
          transitionToNextStep(2);
        }, 1000); // 1 second delay
      }
    },
    [transitionToNextStep]
  );

  const handleJobDescriptionUpload = useCallback(
    (file) => {
      setJobDescriptionFile(file);
      if (file) {
        setTimeout(() => {
          transitionToNextStep(3);
        }, 1000); // 1 second delay
      }
    },
    [transitionToNextStep]
  );

  const handleCompleteCVConversion = useCallback(async () => {
    try {
      if (!resumeFile) {
        console.error('No CV file selected');
        alert('Please select a CV file before proceeding.');
        return;
      }

      setIsProcessing(true);

      const data = await initiateBulkCVConversion(
        [resumeFile],
        jobDescriptionFile,
        adherence ? 'flexible' : 'strict', // Corrected mapping
        perspective ? 'third' : 'first',
        ['concise', 'balanced', 'detailed'][textLength],
        5, // Default experience section limit
        5, // Default project section limit
        10, // Default skill section limit
        template
      );
      const taskId = data.task_id;

      const pollInterval = setInterval(async () => {
        try {
          const pollResult = await getCVConversionResults(taskId);

          if (pollResult.status === 'Completed') {
            clearInterval(pollInterval);

            const { data: downloadResponse, filename, isZip } = await downloadConvertedResumes(
              taskId,
              null, // cvIcon?.preview
              null, // cvIcon?.type
              null, // cvIcon?.name
              template
            );
            const downloadUrl = window.URL.createObjectURL(downloadResponse);
            setDownloadUrl(downloadUrl);
            setDownloadFilename(filename);
            setIsProcessing(false);
            transitionToNextStep(5);

            console.log('Download prepared:', { filename, isZip });
          } else if (pollResult.status === 'Failed' || pollResult.status === 'Error') {
            throw new Error('CV conversion failed');
          }
        } catch (error) {
          console.error('Error fetching CV conversion results:', error);
          clearInterval(pollInterval);
          setIsProcessing(false);
          alert('An error occurred during CV conversion. Please try again.');
          transitionToNextStep(1);
        }
      }, 4000);
    } catch (error) {
      console.error('Conversion process failed:', error);
      setIsProcessing(false);
      alert('An error occurred during CV conversion. Please try again.');
      transitionToNextStep(1);
    }
  }, [resumeFile, jobDescriptionFile, adherence, perspective, textLength, template, transitionToNextStep]);

  const resetDemo = useCallback(() => {
    setTransitioning(true);
    setTimeout(() => {
      setStep(1);
      setResumeFile(null);
      setJobDescriptionFile(null);
      setTemplate('Generic'); // Corrected to match initial state
      setAdherence(false);
      setTextLength(1);
      setPerspective(false);
      setIsProcessing(false);
      setDownloadUrl(null);
      setDownloadFilename(null);
      setTransitioning(false);
    }, 500);
  }, []);

  useEffect(() => {
    if (step === 4) {
      handleCompleteCVConversion();
    }
  }, [step, handleCompleteCVConversion]);

  useEffect(() => {
    let interval;
    if (isProcessing) {
      interval = setInterval(() => {
        setFadeIn(false);
        setTimeout(() => {
          setCurrentFact((prevFact) => (prevFact + 1) % facts.length);
          setFadeIn(true);
        }, 500); // Wait for fade out before changing fact
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [isProcessing]);

  const renderStepContent = useCallback(() => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-8 animate-fadeIn">
            <h2 className="text-2xl font-semibold mb-6 text-gray-800">Upload Your Resume</h2>
            <FileUpload
              id="resume-upload"
              isResume={true}
              onFileUpload={handleResumeUpload}
              selectedFile={resumeFile}
            />
            {/* New grey box */}
            <div className="bg-gray-100 mb-2 rounded-lg p-4 text-sm text-gray-700 text-center">
              Have any technical questions?
              <a
                href="/Application/Chat"
                className="ml-1 text-blue-600 hover:text-blue-800 underline font-medium transition-colors duration-200 ease-in-out"
              >
                Why not chat with us here.
              </a>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-8 animate-fadeIn">
            <h2 className="text-2xl font-semibold mb-6 text-deepNavy">Upload Job Description (Optional)</h2>
            <FileUpload
              id="job-description-upload"
              isResume={false}
              onFileUpload={handleJobDescriptionUpload}
              selectedFile={jobDescriptionFile}
            />
            <div className="text-center">
              <button
                onClick={() => transitionToNextStep(3)}
                className="text-primaryBlue underline text-lg hover:text-deepNavy transition-colors"
              >
                Skip this step
              </button>
            </div>
            {/* New grey box */}
            <div className="bg-gray-100 mb-2 rounded-lg p-4 text-sm text-gray-700 text-center">
              Have any technical questions?
              <a
                href="/Application/Chat"
                className="ml-1 text-blue-600 hover:text-blue-800 underline font-medium transition-colors duration-200 ease-in-out"
              >
                Why not chat with us here.
              </a>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="space-y-8 animate-fadeIn px-4 sm:px-6 lg:px-8">
            <h2 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8 text-gray-800 text-center">
              Customise Your Resume
            </h2>
            <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 space-y-6 sm:space-y-8">
              {/* Template Style */}
              <div className="space-y-4">
                <label htmlFor="template" className="block text-base sm:text-lg font-medium text-gray-700">
                  Template Style
                </label>
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3 sm:gap-4">
                  {templates.map((style) => (
                    <button
                      key={style}
                      onClick={() => setTemplate(style)}
                      className={`p-3 sm:p-4 rounded-lg border-2 transition-all ${
                        template === style
                          ? 'border-gray-400 bg-gray-100'
                          : 'border-gray-200 hover:border-gray-400'
                      }`}
                    >
                      <div className="w-full h-16 sm:h-24 bg-gray-200 rounded-md flex items-center justify-center mb-2 sm:mb-3">
                        <svg
                          className="w-8 h-8 sm:w-12 sm:h-12 text-gray-500"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </div>
                      <span className="block text-center capitalize text-gray-700 font-medium text-sm sm:text-base">
                        {style}
                      </span>
                    </button>
                  ))}
                </div>
              </div>

              <div className="space-y-5 sm:space-y-6">
                {/* Adherence */}
                <div className="space-y-3 sm:space-y-4">
                  <div className="flex items-center space-x-2">
                    <label htmlFor="adherence" className="text-base sm:text-lg font-medium text-gray-700">
                      Adherence
                    </label>
                  </div>
                  <div className="flex justify-between items-center space-x-3 sm:space-x-4">
                    {adherences.map((option, index) => (
                      <button
                        key={option}
                        onClick={() => setAdherence(index === 1)}
                        className={`flex-1 py-2 sm:py-3 px-3 sm:px-4 rounded-lg text-center transition-all ${
                          (index === 0 && !adherence) || (index === 1 && adherence)
                            ? 'bg-gray-200 text-gray-800 font-medium shadow-sm'
                            : 'bg-white text-gray-600 border border-gray-300 hover:border-gray-400'
                        }`}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Perspective */}
                <div className="space-y-3 sm:space-y-4">
                  <div className="flex items-center space-x-2">
                    <label htmlFor="perspective" className="text-base sm:text-lg font-medium text-gray-700">
                      Perspective
                    </label>
                  </div>
                  <div className="flex justify-between items-center space-x-3 sm:space-x-4">
                    {perspectives.map((option, index) => (
                      <button
                        key={option}
                        onClick={() => setPerspective(index === 1)}
                        className={`flex-1 py-2 sm:py-3 px-3 sm:px-4 rounded-lg text-center transition-all ${
                          (index === 0 && !perspective) || (index === 1 && perspective)
                            ? 'bg-gray-200 text-gray-800 font-medium shadow-sm'
                            : 'bg-white text-gray-600 border border-gray-300 hover:border-gray-400'
                        }`}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>

                {/* Text Length */}
                <div className="space-y-3 sm:space-y-4">
                  <label htmlFor="textLength" className="block text-base sm:text-lg font-medium text-gray-700">
                    Text Length
                  </label>
                  <div className="flex flex-wrap sm:flex-nowrap justify-between items-center gap-2 sm:space-x-4">
                    {textlength.map((option, index) => (
                      <button
                        key={option}
                        onClick={() => setTextLength(index)}
                        className={`flex-1 py-2 sm:py-3 px-3 sm:px-4 rounded-lg text-center transition-all ${
                          textLength === index
                            ? 'bg-gray-200 text-gray-800 font-medium shadow-sm'
                            : 'bg-white text-gray-600 border border-gray-300 hover:border-gray-400'
                        }`}
                      >
                        {option}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => transitionToNextStep(4)}
                  className="bg-gray-700 text-white px-5 sm:px-6 py-2 sm:py-3 rounded-lg text-base sm:text-lg font-semibold hover:bg-gray-800 transition-colors mt-6 sm:mt-8 flex items-center justify-center shadow-md"
                >
                  Start Conversion <ChevronRight className="ml-2 h-4 w-4 sm:h-5 sm:w-5" />
                </button>
              </div>
            </div>
            {/* New grey box */}
            <div className="bg-gray-100 mb-2 rounded-lg p-4 text-sm text-gray-600 text-center">
              Contact us to have your Corporate CV converted into a customisable template for use with PivotCV.
              This will allow you to easily format CVs according to your company's requirements.
            </div>
          </div>
        );
      case 4:
        return (
          <div className="flex flex-col items-center justify-between h-full min-h-[600px] animate-fadeIn">
            <div className="flex-grow flex flex-col items-center justify-center w-full max-w-md mx-auto space-y-12">
              <Loader2 className="h-24 w-24 animate-spin text-primaryBlue" />
              <h2 className="text-3xl font-bold text-deepNavy text-center">Processing your resume...</h2>
              <div className="w-full space-y-4">
                <p className="text-sm text-slateGrey text-center">This may take a few moments</p>
              </div>
            </div>
            <div className="w-full max-w-md mx-auto mt-12 mb-6">
              <div className="bg-lightGrey bg-opacity-50 rounded-lg p-4">
                <h3 className="text-lg font-semibold text-deepNavy mb-2">Did you know?</h3>
                <p
                  className={`text-sm text-slateGrey transition-opacity duration-500 ease-in-out ${
                    fadeIn ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  {facts[currentFact]}
                </p>
              </div>
            </div>
          </div>
        );
      case 5:
        return (
          <div className="space-y-8 animate-fadeIn">
            <h2 className="text-center text-2xl font-semibold mb-6 text-gray-800">
              Your processed resume is ready!
            </h2>
            <div className="text-center">
              <FileText className="h-24 w-24 mx-auto mb-6 text-gray-700" />
              <p className="text-xl mb-6 text-gray-800">Preview of your processed resume</p>
              <a
                href={downloadUrl}
                download={downloadFilename}
                className="inline-block w-full bg-gray-800 text-white px-6 py-3 rounded-md text-lg hover:bg-gray-700 transition-colors"
              >
                <Download className="inline-block mr-2 h-6 w-6" /> Download Resume
              </a>
            </div>
            <button
              onClick={resetDemo}
              className="w-full border border-gray-300 text-gray-700 px-6 py-3 rounded-md text-lg hover:bg-gray-100 transition-colors"
            >
              <RefreshCw className="inline-block mr-2 h-6 w-6" /> Start Over
            </button>
            {/* New grey box */}
            <div className="bg-gray-100 mb-2 rounded-lg p-4 text-sm text-gray-700 text-center">
              Have any technical questions?
              <a
                href="/Application/Chat"
                className="ml-1 text-blue-600 hover:text-blue-800 underline font-medium transition-colors duration-200 ease-in-out"
              >
                Why not chat with us here.
              </a>
            </div>
          </div>
        );
      default:
        return null;
    }
  }, [
    step,
    handleResumeUpload,
    handleJobDescriptionUpload,
    transitionToNextStep,
    template,
    adherence,
    perspective,
    textLength,
    fadeIn,
    currentFact,
    downloadUrl,
    downloadFilename,
    resetDemo,
    resumeFile,
    jobDescriptionFile,
  ]);

  return (
    <Appshell currentPage="DEMO CV Converter" loginRequired={true}>
      <div className="min-h-screen w-full bg-white flex items-center justify-center p-4">
        <div className="w-full max-w-5xl mx-auto bg-white rounded-lg shadow-xl">
          <div className="flex justify-center items-center p-4">
            <a href="/" aria-label="Go to homepage">
              <img className="h-16 w-16" src="/PivotCV-Icon-Darkest.svg" alt="PivotCV Logo" />
            </a>
          </div>
          <div className="px-8">
            <div
              className={`w-full transition-opacity duration-400 ${
                transitioning ? 'opacity-0' : 'opacity-100'
              }`}
            >
              <div className="bg-white rounded-lg px-8 min-h-[550px] flex flex-col justify-between">
                {renderStepContent()}
              </div>
            </div>
          </div>
          <div className="p-4 bg-white shadow-sm rounded-lg">
            <div className="w-full flex justify-between items-center">
              <button
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => step > 1 && step < 5 && transitionToNextStep(step - 1)}
                disabled={step === 1 || step === 4 || step === 5}
                aria-label="Previous step"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <div className="space-x-3">
                {[1, 2, 3, 4, 5].map((s) => (
                  <span
                    key={s}
                    className={`inline-block w-3 h-3 rounded-full transition-all duration-300 ${
                      s === step
                        ? 'bg-blue-500 scale-125'
                        : s < step
                        ? 'bg-blue-200'
                        : 'bg-gray-200'
                    }`}
                    aria-current={s === step ? 'step' : undefined}
                  />
                ))}
              </div>
              <button
                className="text-gray-500 hover:text-gray-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed"
                onClick={() => step < 4 && transitionToNextStep(step + 1)}
                disabled={(step === 1 && !resumeFile) || step === 3 || step === 4 || step === 5}
                aria-label="Next step"
              >
                <ChevronRight className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Appshell>
  );
}
