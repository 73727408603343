import React, { useState, useEffect, useContext } from 'react';
import AppShell from '../../components/layout/AppShell';
import { CheckIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import { Disclosure } from '@headlessui/react'
import Spinner from '../../components/layout/Spinner';
import AlertPopup from '../../components/layout/AlertPopUp';
import { createCheckoutSession } from '../../services/subscriptionService';
import { UserContext } from '../../contexts/UserContext';
import { Helmet } from 'react-helmet';

const tiers = [
  {
    name: 'Demo Plan',
    id: 'demo-plan',
    pricingId: 'price_1P9Hw8DhA7ZbzN8UALgb05DP',
    href: '#',
    priceMonthly: '0',
    description: '',
    features: [
      '3 Credits',
      'Third-person conversion',
      'Format standardisation',
      'Resume personalisation',
      'Branded CVs'
    ],
    mostPopular: false,
  },
  {
    name: 'Personal Plan',
    id: 'personal-plan',
    pricingId: 'price_1P8a1oDhA7ZbzN8UoeUhBk3l',
    href: '#',
    priceMonthly: '29.99',
    description: '',
    features: [
      '200 Credits',
      'Third-person conversion',
      'Format standardisation',
      'Resume personalisation',
      'Branded CVs'
    ],
    mostPopular: true,
  },
  {
    name: 'Professional Plan',
    id: 'professional-plan',
    pricingId: 'price_1P9HvgDhA7ZbzN8UYRcttWQ4',
    href: '#',
    priceMonthly: '299.99',
    description: '',
    features: [
      '2000 Credits',
      'Third-person conversion',
      'Format standardisation',
      'Resume personalisation',
      'Branded CVs'
    ],
    mostPopular: false,
  },
]

const faqs = [
  {
    question: "What file formats do you support?",
    answer:
      "We support a wide range of file formats, including PDF, Word (.doc and .docx), and plain text. Simply upload your existing resume, and our app will handle the rest.",
  },
  {
    question: "How does the resume personalisation feature work?",
    answer:
      "Our resume personalisation feature allows you to tailor your CV to specific job descriptions or requirements. Simply upload the relevant document, and our app will highlight the key skills and experiences that align with the employer's needs.",
  },
  {
    question: "Is there a limit to the number of CVs I can create?",
    answer:
      "With our Professional plan, you can create and optimize up to 2000 CVs a month. We believe in providing you with the flexibility and freedom to create as many tailored resumes as you need.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept all major credit cards, including Visa, Mastercard, and American Express. Our payment process is secure and encrypted to ensure the safety of your personal information.",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Pricing = () => {
  const [success, setSuccess] = useState(null);
  const { user, loading: userLoading } = useContext(UserContext) || {};
  const [error, setError] = useState(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      checkSubscriptionStatus();
    }
  }, []);

  const checkSubscriptionStatus = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');

    if (status === 'success') {
      setSuccess('Your subscription has been activated.');
    } else if (status === 'cancelled') {
      setError('You did not complete the checkout process. Please complete it to access the software.');
    }
  };

  const handleSubscription = async (pricingId) => {
    if (typeof window !== 'undefined') {
      if (user) {
        setError(null);

        try {
          const currentUrl = window.location.href;
        
          const baseUrl = currentUrl.split('?')[0];

          const checkoutSessionUrl = await createCheckoutSession(
            user.email,
            pricingId,
            `${baseUrl}?session_id={CHECKOUT_SESSION_ID}`,
            `${baseUrl}?session_id={CHECKOUT_SESSION_ID}`
          );

          window.location.href = checkoutSessionUrl;
        } catch (error) {
          console.error('Error creating checkout session:', error);

          // Check if the error response contains the error message
          if (error.response && error.response.data && error.response.data.error) {
            setError(error.response.data.error);
          } else {
            setError('An error occurred. Please try again later.');
          }
        }
      } else {
        window.location.href = '/login';
      }
    }
  };

  return (
    <AppShell currentPage="Pricing" loginRequired={false}>
      <Helmet>
        <title>PivotCV | Pricing | CV Converter - AI-Powered Resume Tool</title>
        <meta
          name="description"
          content="Explore the pricing options for our AI-powered CV Converter. Create a professional, tailored resume for free and unlock additional features with our affordable subscription plans. Boost your job search without breaking the bank."
        />
        <meta
          name="keywords"
          content="pricing, CV converter pricing, resume converter pricing, free CV converter, free resume converter, AI-powered CV converter, AI-driven resume conversion, convert CV online, convert resume online, tailor CV, tailor resume, customize CV, customize resume, optimize CV, optimize resume, professional CV, professional resume, affordable CV tool, affordable resume tool, subscription plans"
        />
      </Helmet>
      <main>
        <div className="min-h-[calc(100vh-25vh)] opacity-0 animate-fadeIn">
          <div
            className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
            aria-hidden="true"
          >
            <div
              className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#50E3C2] to-[#7ED321] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>



          <div className="min-h-[calc(100vh-25vh)] py-24 sm:py-24">
            <div className="min-h-[calc(100vh-25vh)] mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <p className="text-base font-semibold leading-7 text-primaryBlue">Simple, transparent pricing</p>
                <h2 className="mt-2 text-4xl font-bold tracking-tight text-darkGrey sm:text-6xl">Pricing</h2>
                <p className="mt-6 text-lg leading-8 text-slateGrey">
                  Unlock the full potential of your CV with our powerful tools and features. No hidden fees, no complicated plans - just a simple, monthly subscription.
                  Supercharge your job search with our comprehensive CV conversion and optimiation tools.            </p>

              </div>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                  {tiers.map((tier, tierIdx) => (
                    <div
                      key={tier.id}
                      className={classNames(
                        tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                        tierIdx === 0 ? 'lg:rounded-r-none' : '',
                        tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                        'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'
                      )}
                    >
                      <div>
                        <div className="flex items-center justify-between gap-x-4">
                          <h3
                            id={tier.id}
                            className={classNames(
                              tier.mostPopular ? 'text-primaryBlue' : 'text-darkGrey',
                              'text-lg font-semibold leading-8'
                            )}
                          >
                            {tier.name}
                          </h3>
                          {tier.mostPopular ? (
                            <p className="rounded-full bg-primaryBlue/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primaryBlue">
                              Most popular
                            </p>
                          ) : null}
                        </div>
                        <p className="mt-4 text-sm leading-6 text-slateGrey">{tier.description}</p>
                        <p className="mt-6 flex items-baseline gap-x-1">
                          <span className="text-4xl font-bold tracking-tight text-darkGrey">{tier.priceMonthly}</span>
                          <span className="text-sm font-semibold leading-6 text-slateGrey">/month</span>
                        </p>
                        <ul className="mt-8 space-y-3 text-sm leading-6 text-slateGrey">
                          {tier.features.map((feature) => (
                            <li key={feature} className="flex gap-x-3">
                              <CheckIcon className="h-6 w-5 flex-none text-primaryBlue" aria-hidden="true" />
                              {feature}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubscription(tier.pricingId);
                        }}
                        disabled={userLoading}
                        aria-describedby={tier.id}
                        className={classNames(
                          tier.mostPopular
                            ? 'bg-primaryBlue text-white shadow-sm hover:bg-lightBlue'
                            : 'text-primaryBlue ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                          'mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                        )}                      >
                        {userLoading ? <Spinner /> : user ? 'Get access' : 'Log in'}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Pricing section */}
            {success && (
              <AlertPopup message={success} type="success" onClose={() => setSuccess(null)} />
            )}

            {error && (
              <AlertPopup message={error} type="error" onClose={() => setError(null)} />
            )}

            {/* FAQ section */}
            <div className="mx-auto my-12 max-w-7xl px-6 sm:my-12 lg:px-8">
              <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
                <h2 className="text-2xl font-bold leading-10 tracking-tight text-darkGrey">Frequently asked questions</h2>
                <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                  {faqs.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full flex-col items-start justify-between text-left text-darkGrey">
                            <div className="w-full flex items-center justify-between">
                              <span className="text-base font-semibold leading-7">{faq.question}</span>
                              <span className="ml-6 h-7 flex items-center">
                                {open ? (
                                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                )}
                              </span>
                            </div>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12 w-full">
                              <p className="text-base leading-7 text-slateGrey">{faq.answer}</p>
                            </Disclosure.Panel>
                          </Disclosure.Button>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </main>
      {
        success && (
          <AlertPopup message={success} type="success" onClose={() => setSuccess(null)} />
        )
      }

      {
        error && (
          <AlertPopup message={error} type="error" onClose={() => setError(null)} />
        )
      }
    </AppShell >
  );
};

Pricing.propTypes = {
  user: PropTypes.object,
};

export default Pricing;