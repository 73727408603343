import { useState, useEffect } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { uploadDefaultCVIcon, removeDefaultCVIcon } from '../../services/userService';
import AlertPopup from '../../components/layout/AlertPopUp';
import Spinner from '../../components/layout/Spinner';
import Tooltip from '../layout/Tooltip';

export default function IconUpload({ savedIcon, updateIcon }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const allowedFormats = [
    'image/png',
    'image/jpeg',
    'image/webp',
    'image/gif',
    'image/bmp',
    'image/tiff',
  ];
  const maxSize = 5 * 1024 * 1024; // 5MB

  useEffect(() => {
    if (savedIcon && !selectedFile) {
      setPreviewUrl(savedIcon.preview);
    }
  }, [savedIcon, selectedFile]);

  useEffect(() => {
    return () => {
      if (previewUrl && (!savedIcon || previewUrl !== savedIcon.preview)) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl, savedIcon]);

  const validateFile = (file) => {
    return file && allowedFormats.includes(file.type) && file.size <= maxSize;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFile(file)) {
      setSelectedFile(file);
      setUploadStatus(null);
      setAlertMessage('File selected. Please save to upload.');
      setAlertType('info');
      if (previewUrl && previewUrl !== savedIcon?.preview) {
        URL.revokeObjectURL(previewUrl);
      }
      const newPreview = URL.createObjectURL(file);
      setPreviewUrl(newPreview);
    } else {
      setSelectedFile(null);
      setUploadStatus('error');
      setAlertMessage('Invalid file format or size. Please select an image file up to 5MB.');
      setAlertType('error');
      if (previewUrl && previewUrl !== savedIcon?.preview) {
        URL.revokeObjectURL(previewUrl);
      }
      setPreviewUrl(savedIcon?.preview || null);
    }
    setShowAlert(true);
  };

  const handleDragEvents = (event) => {
    event.preventDefault();
    if (event.type === 'dragover') {
      setIsDraggingOver(true);
    } else {
      setIsDraggingOver(false);
    }
  };

  const handleSaveIcon = async () => {
    if (selectedFile) {
      try {
        setIsLoading(true);
        const response = await uploadDefaultCVIcon(selectedFile);

        console.log('Response:', response);

        if (response.status === 200 && response.data.status === 'success') {
          // Success logic
          setAlertMessage(response.data.message);
          setAlertType('success');
        } else {
          throw new Error(response.data.message || 'Upload failed');
        }
      } catch (error) {
        console.error('Error uploading icon:', error);

        if (error.response && error.response.data) {
          setAlertMessage(error.response.data.message || 'Failed to upload and save icon. Please try again.');
        } else {
          setAlertMessage('Failed to upload and save icon. Please try again.');
        }

        setUploadStatus('error');
        setAlertType('error');
      } finally {
        setIsLoading(false);
        setShowAlert(true);
      }
    }
  };


  const handleRemoveIcon = async () => {
    try {
      setIsLoading(true);
      const response = await removeDefaultCVIcon();

      console.log('Full response:', response);
      console.log('HTTP status code:', response.status);
      console.log('Response data:', response.data);

      if (response.status === 200 && response.data.status === 'success') {
        // Success logic
        setAlertMessage(response.data.message);
        setAlertType('success');
      } else {
        throw new Error(response.data.message || 'Removal failed');
      }
    } catch (error) {
      console.error('Error removing icon:', error);

      if (error.response) {
        console.log('Error response status:', error.response.status);
        console.log('Error response data:', error.response.data);
      } else if (error.request) {
        console.log('No response received:', error.request);
      } else {
        console.log('Error setting up request:', error.message);
      }

      if (error.response && error.response.data) {
        setAlertMessage(error.response.data.message || 'Failed to remove icon. Please try again.');
      } else {
        setAlertMessage('Failed to remove icon. Please try again.');
      }

      setUploadStatus('error');
      setAlertType('error');
    } finally {
      setIsLoading(false);
      setShowAlert(true);
    }
  };


  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (
    <div className="col-span-full">
      <Tooltip message="This Icon will be used to brand your outputted CV." position="right">
        <label htmlFor="icon-upload" className="block text-sm font-medium leading-6 text-slate-900">
          Icon Image
        </label>
      </Tooltip>
      <div
        className={`mt-2 flex flex-col items-center justify-center rounded-lg border border-dashed px-6 py-10 ${uploadStatus === 'success'
          ? 'border-green-500'
          : uploadStatus === 'error'
            ? 'border-red-500'
            : 'border-lightGrey'
          } ${isDraggingOver ? 'bg-gray-100' : ''}`}
        onDragOver={handleDragEvents}
        onDragEnter={handleDragEvents}
        onDragLeave={handleDragEvents}
        onDrop={handleDragEvents}
      >
        {isLoading ? (
          <Spinner />
        ) : previewUrl || savedIcon ? (
          <>
            <img
              src={previewUrl || savedIcon.preview}
              alt={previewUrl ? 'Selected Icon' : 'Saved Icon'}
              className="mx-auto h-12 w-auto"
            />
            {selectedFile ? (
              <button
                type="button"
                className="mt-5 bg-primaryBlue text-white px-4 py-2 text-sm rounded-md hover:bg-indigo-700"
                onClick={handleSaveIcon}
                disabled={isLoading}
              >
                {isLoading ? 'Saving...' : 'Save Icon'}
              </button>
            ) : (
              <button
                type="button"
                className="mt-5 bg-red-100 text-red-600 px-4 py-1 text-sm rounded-md hover:bg-red-200"
                onClick={handleRemoveIcon}
                disabled={isLoading}
              >
                {isLoading ? 'Removing...' : 'Remove Icon'}
              </button>
            )}
          </>
        ) : (
          <div className="text-center">
            <PhotoIcon
              className={`mx-auto h-12 w-12 text-slate-800 transition-transform duration-500 ease-in-out ${isDraggingOver ? 'scale-125' : ''
                }`}
              aria-hidden="true"
            />
            <div className="mt-4 flex text-sm leading-6 text-slateGrey">
              <label
                htmlFor="file-upload"
                className={`relative cursor-pointer rounded-md font-semibold text-primaryBlue focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-lightBlue ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
              >
                <span>Upload a file</span>
                <input
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={handleFileChange}
                  disabled={isLoading}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-slateGrey">
              PNG, JPG, JPEG, WebP, GIF, BMP, or TIFF up to 5MB
            </p>
          </div>
        )}
      </div>
      {showAlert && (
        <AlertPopup
          message={alertMessage}
          type={alertType}
          onClose={handleCloseAlert}
          duration={3000}
        />
      )}
    </div>
  );
}
